import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { Helmet } from 'react-helmet';

import DisplaySide from './DisplaySide';
import FormSide from './FormSide';
import ApiManager from 'ApiManager';
import { CLOUDS } from 'ApiUrls';

const useStyles = makeStyles({
  name: 'RegisterPage',
})((theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.background.default,
    zIndex: 3000,
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'grid',
    overflowX: 'auto',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

const RegisterPage = () => {
  const { classes } = useStyles();

  return (
    <div className={ApiManager.cloud === CLOUDS.ellipsis && classes.root}>
      <Helmet>
        <title>Sign up - {ApiManager?.displayName ?? 'Ellipsis Drive'}</title>
      </Helmet>
      {ApiManager.cloud === CLOUDS.ellipsis && <DisplaySide />}
      <FormSide />
    </div>
  );
};

export default RegisterPage;
