import React, { useState } from 'react';

import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, TextField } from '@mui/material';

import copy from 'copy-to-clipboard';
import AccessLevelButton from 'ReusableComponents/AccessLevelComponent/AccessLevelButton';

import ApiManager from '../../ApiManager';
import { useMainContext } from 'ReusableComponents/MainContext';
import { useMinimalAuth } from 'hooks';
import { createUrl } from 'ReusableComponents/CreateUrl';
import GeoFenceElement from 'ReusableComponents/ManageSharing/LayerRow/GeoFenceElement';
import Geofence from 'ReusableComponents/ManageSharing/Geofence/Geofence';

export const protocols = [
  { displayName: 'WFS', name: 'wfs', access: ApiManager.newAccessLevel['view'], type: 'vector' },
  { displayName: 'WFS-t', name: 'wfs', access: ApiManager.newAccessLevel['edit'], type: 'vector' },
  { displayName: 'Vector tiles', name: 'mvt', access: ApiManager.newAccessLevel['view'], type: 'vector' },
  { displayName: 'OGC Features', name: 'features', access: ApiManager.newAccessLevel['view'], type: 'vector' },
  {
    displayName: 'Vector tile style sheet',
    name: 'mvtStyle',
    access: ApiManager.newAccessLevel['view'],
    type: 'vector',
  },
  //{ displayName: 'WMTS', name: 'wmts', access: ApiManager.newAccessLevel['view'], type: 'vector' },
  //{ displayName: 'WMS', name: 'wms', access: ApiManager.newAccessLevel['view'], type: 'vector' },
  { displayName: 'XYZ', name: 'xyz', access: ApiManager.newAccessLevel['view'], type: 'raster' },
  { displayName: 'STAC collection', name: 'STAC', access: ApiManager.newAccessLevel['view+'], type: 'raster' },
  { displayName: 'STAC collection', name: 'STAC', access: ApiManager.newAccessLevel['view+'], type: 'vector' },
  { displayName: 'STAC collection', name: 'STAC', access: ApiManager.newAccessLevel['view+'], type: 'pointCloud' },
  { displayName: 'STAC catalog', name: 'STACcatalog', access: ApiManager.newAccessLevel['view+'], type: 'folder' },
  { displayName: 'Vector XYZ', name: 'vector xyz', access: ApiManager.newAccessLevel['view'], type: 'pointCloud' },
  { displayName: '3D tiles', name: '3dtiles', access: ApiManager.newAccessLevel['view'], type: 'pointCloud' },
  { displayName: 'WMTS', name: 'wmts', access: ApiManager.newAccessLevel['view'], type: 'raster' },
  { displayName: 'WMS', name: 'wms', access: ApiManager.newAccessLevel['view'], type: 'raster' },
  { displayName: 'WCS 1.1.1', name: 'wcs', access: ApiManager.newAccessLevel['view+'], type: 'raster' },
  { displayName: 'WCS 1.0.0', name: 'wcs', access: ApiManager.newAccessLevel['view+'], type: 'raster' },
  { displayName: 'terrain RGB', name: 'terrainRgb', access: ApiManager.newAccessLevel['view'], type: 'raster' },
];

export const createLink = (override, primary, map, user, onOpenSnackbar, timestampId, styleId) => {
  if (override) {
    console.log('override', override);
    copy(override);
    onOpenSnackbar({
      content: `Copied ${primary}`,
      level: 'success',
    });
    return;
  }
  let url;
  console.log(primary);
  if (primary === 'Vector tiles') {
    styleId = styleId ? styleId : '<your styleId>';
    timestampId = timestampId ? timestampId : '<your timestampId>';
    url = `${ApiManager.apiUrl}/v3/ogc/mvt/${map.id}/{z}/{x}/{y}?timestampId=${timestampId}`;
  } else if (primary === 'STAC collection') {
    url = `${ApiManager.apiUrl}/v3/ogc/stac/collection/${map.id}`;
  } else if (primary === 'STAC catalog') {
    url = `${ApiManager.apiUrl}/v3/ogc/stac/catalog/${map.id}`;
  } else if (primary === 'Vector tile style sheet') {
    styleId = styleId ? styleId : '<your styleId>';
    timestampId = timestampId ? timestampId : '<your timestampId>';
    url = `${ApiManager.apiUrl}/v3/ogc/mvt/${map.id}/styleSheet?timestampId=${timestampId}&style=${styleId}&zoom=21`;
  } else if (primary === '3D tiles' || primary === 'Vector XYZ') {
    styleId = styleId ? styleId : '<your styleId>';
    timestampId = timestampId ? timestampId : '<your timestampId>';
    url = `${ApiManager.apiUrl}/v3/path/${map.id}/timestamp${timestampId}/tile/{z}/{x}/{y}`;
  } else if (primary === 'terrain RGB') {
    timestampId = timestampId ? timestampId : '<your timestampId>';

    url = `${ApiManager.apiUrl}/v3/path/${map.id}/raster/timestamp/${timestampId}/tile/{z}/{x}/{y}?style=${createUrl(
      `{"method":"terrainRgb", "parameters":{"alpha":1, "bandNumber":1, "offset":150} }`
    )}`;
  } else if (primary === 'XYZ') {
    console.log('here');
    styleId = styleId ? styleId : '<your styleId>';
    timestampId = timestampId ? timestampId : '<your timestampId>';
    url = `${ApiManager.apiUrl}/v3/path/${map.id}/raster/timestamp/${timestampId}/tile/{z}/{x}/{y}?style=${createUrl(
      styleId
    )}`;
  } else {
    url = `${ApiManager.apiUrl}/v3/ogc/${protocols.find((p) => p.displayName === primary).name}/${
      map.id
    }?request=getCapabilities`;
  }

  let version;
  if (primary === 'WCS 1.1.1') {
    version = '1.1.1';
  } else if (primary === 'WCS 1.0.0') {
    version = '1.0.0';
  }
  if (version) {
    url = url + '&version=' + version;
  }
  if (!user || (map.path?.root !== 'sharedWithMe' && map.path?.root !== 'myMaps')) {
    copy(url);
    onOpenSnackbar({
      content: `Copied ${primary} link`,
      level: 'success',
    });
    return;
  }
  let description = (primary + ' access token for ' + map.name).substring(0, 63);

  let body = {
    scope: primary === 'STAC collection' || primary === 'STAC catalog' ? 'all' : 'ogc',
    description: description,
    accessList: [{ pathId: map.id, access: { accessLevel: protocols.find((p) => p.displayName === primary).access } }],
  };
  ApiManager.post('/v3/account/security/accessToken', body, user)
    .then((r) => {
      if (primary === 'STAC collection' || primary === 'STAC catalog') {
        url = url + `/` + r.token;
      } else {
        url = url + `&token=` + r.token;
      }

      copy(url);
      onOpenSnackbar({
        content: `Copied ${primary} link`,
        level: 'success',
      });
    })
    .catch((e) => {
      onOpenSnackbar({
        content: e.message,
        level: 'error',
      });
    });
};

export function CreateTokenDialog({ layer, onClose }) {
  const { onOpenSnackbar } = useMainContext();
  const [accessLevel, setAccessLevel] = useState(layer.yourAccess.accessLevel);
  const [name, setName] = useState('My token for layer ' + layer.name);
  const [geoFence, setGeoFence] = useState(null);
  const user = useMinimalAuth();
  const createToken = () => {
    const body = {
      scope: 'all',
      description: name,
      accessList: [{ pathId: layer.id, access: { accessLevel: accessLevel, geoFence } }],
    };
    ApiManager.post('/v3/account/security/accessToken', body, user)
      .then((r) => {
        copy(r.token);
        onOpenSnackbar({
          content: `Copied token to clipboard`,
          level: 'success',
        });
      })
      .catch((e) => {
        onOpenSnackbar({
          content: e.message,
          level: 'error',
        });
      });
    onClose();
  };
  const noGeoFence =
    layer?.type === 'folder' || layer?.type === 'bookmark' || layer?.type === 'file' || layer?.type === 'process';
  console.log('geofence', geoFence, noGeoFence);
  return (
    <Dialog
      open={true}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{'Create personal access token.'}</DialogTitle>
      <DialogContent>
        <table>
          <tr>
            <th>Access level for token</th>
            <th>Geofence for token</th>
          </tr>
          <tr>
            <td>
              <AccessLevelButton
                accessLevel={accessLevel}
                skeleton={false}
                targetUser={true}
                displayEdit={false}
                onChange={(a) => {
                  setAccessLevel(a);
                }}
                menuTitle={'Pick Access Level'}
                min={100}
                max={layer.yourAccess.accessLevel}
                disabled={layer.yourAccess.accessLevel === 100}
                path={layer}
              />
            </td>
            <td>
              <GeoFenceElement
                tooltipLabel={
                  noGeoFence
                    ? `Not available for ${layer?.type}s`
                    : layer?.yourAccess.accessLevel < ApiManager.newAccessLevel['edit+']
                    ? 'Access Level of edit+ required to use this option'
                    : accessLevel >= ApiManager.newAccessLevel['view+']
                    ? 'Can only apply a geofence for access level view'
                    : 'Grant access to only a sub region of the layer'
                }
                disabled={
                  accessLevel >= ApiManager.newAccessLevel['view+'] ||
                  noGeoFence ||
                  layer?.yourAccess.accessLevel < ApiManager.newAccessLevel['edit+']
                }
                tiles={geoFence?.tiles}
                maxZoom={geoFence?.maxZoom}
                type="public"
                handleChange={(res) => setGeoFence(res)}
                path={layer}
              />
            </td>
          </tr>
        </table>
        <div style={{ 'margin-top': '20px' }}>
          <TextField
            id="outlined-basic"
            fullWidth
            label="Type a name for your token"
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
          />
        </div>
        <DialogContentText>Once created you can revoke this token in your account settings</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={!name} onClick={createToken}>
          {' '}
          Create Token
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const getText = (override, primary, map, user) => {
  if (override || override === 0) {
    return { hasAccess: true, text: override };
  }
  let hasAccess = map.yourAccess.accessLevel >= protocols.find((p) => p.displayName === primary).access;
  let personal = map.path?.root === 'sharedWithMe' || map.path?.root === 'myMaps';
  let text = hasAccess
    ? personal
      ? 'Create personal ' + primary + ' link'
      : 'Create ' + primary + ' link'
    : user
    ? 'Insufficient access for ' + primary
    : 'Insufficient access for ' + primary + ', try to log in';

  return { text: text, hasAccess: hasAccess };
};
