import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import { POLLING_RATE, useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useMinimalAuth } from 'hooks';
import ApiManager from 'ApiManager';
import {
  Box,
  CircularProgress,
  Collapse,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
} from '@mui/material';
import { Cancel, Check, CheckCircle, KeyboardArrowDown } from '@mui/icons-material';
import moment from 'moment';
import NewJobButton from 'ReusableComponents/Process/NewJobButton';

const ProcessComponent = forwardRef(({ onContextMenu }, ref) => {
  const { currentFolderInfo } = useCurrentFolder();

  const [jobsOpen, setJobsOpen] = useState(false);
  const [openJob, setOpenJob] = useState(false);

  const user = useMinimalAuth();

  const [jobs, setJobs] = useState([]);

  const getJobs = useCallback(() => {
    let url;
    const fullAccess = currentFolderInfo.yourAccess.accessLevel >= ApiManager.newAccessLevel['edit+'];
    console.log('full', fullAccess, currentFolderInfo.yourAccess.accessLevel, ApiManager.newAccessLevel['edit+']);
    if (fullAccess) {
      url = `/v3/path/${currentFolderInfo.id}/process/job`;
    } else {
      url = `/v3/path/process/job`;
    }

    ApiManager.get(url, {}, user)
      .then((res) => {
        res = res.filter((x) => x.path.id === currentFolderInfo.id);
        console.log('res', res);
        setJobs(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [currentFolderInfo, user]);

  const jobsInterval = useRef();
  useEffect(() => {
    getJobs();

    jobsInterval.current = setInterval(getJobs, POLLING_RATE);
    return () => {
      clearInterval(jobsInterval.current);
      jobsInterval.current = null;
    };
  }, [getJobs]);

  return (
    <Container ref={ref} sx={{ overflow: 'auto' }}>
      <Stack gap={1} py={2}>
        <Typography variant="h3" component="h1" paragraph>
          {currentFolderInfo?.name}
        </Typography>
        {currentFolderInfo?.description && <Typography paragraph>{currentFolderInfo?.description}</Typography>}
        {currentFolderInfo?.process?.gitUrl && (
          <Typography component="a" href={currentFolderInfo?.process?.gitUrl} target="_blank" paragraph>
            {currentFolderInfo?.process?.gitUrl}
          </Typography>
        )}

        <List>
          <ListItemButton
            onClick={() => setJobsOpen((old) => !old)}
            disabled={jobs?.length === 0}
            selected
            sx={(theme) => ({
              borderRadius: theme.spacing(1),
              transition: theme.transitions.create(['border-radius', 'border-color']),
              border: `1px solid transparent`,
              borderBottom: 0,
              ...(jobsOpen ? { borderRadius: theme.spacing(1, 1, 0, 0), borderColor: theme.palette.primary.main } : {}),
            })}
          >
            <ListItemText primary="Job List" />
            <IconButton disableRipple>
              <RotatableArrow in={jobsOpen} />
            </IconButton>
          </ListItemButton>

          <List
            component={Collapse}
            in={jobsOpen}
            sx={(theme) => ({
              border: `1px solid transparent`,
              borderTopWidth: 0,
              borderRadius: theme.spacing(0, 0, 2, 2),
              ...(jobsOpen ? { borderColor: theme.palette.primary.main } : {}),
            })}
          >
            {jobs?.map((j) => (
              <React.Fragment key={j?.id}>
                <ListItemButton onClick={() => setOpenJob((old) => (old !== j?.id ? j?.id : null))}>
                  <ListItemIcon>
                    <IconButton disableRipple>
                      <RotatableArrow in={openJob === j?.id} />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary={moment(j?.date)?.format('L LT')} />
                  <StatusIcon {...j} />
                </ListItemButton>

                <List
                  component={Collapse}
                  in={openJob === j?.id}
                  dense
                  sx={{ py: openJob === j?.id ? 1 : 0, pl: 7, maxHeight: 200, overflowY: 'auto' }}
                >
                  <JobListItem title="status" content={j?.status} />
                  <JobListItem
                    title="message"
                    content={<pre style={{ whiteSpace: 'break-spaces' }}>{j?.message}</pre>}
                  />
                  <JobListItem title="creator" content={j?.user?.username} />
                  {j?.parameters?.length > 0 && (
                    <List dense>
                      <ListSubheader disableSticky>Parameters</ListSubheader>
                      {j?.parameters?.map((p, i) => (
                        <JobListItem key={`${p?.name}_${i}`} title={p?.name} content={p?.value} />
                      ))}
                    </List>
                  )}
                </List>
              </React.Fragment>
            ))}
          </List>
        </List>

        <Box sx={{ textAlign: 'right' }}>
          <NewJobButton slotProps={{ button: { variant: 'contained' } }} cb={getJobs} />
        </Box>
      </Stack>
    </Container>
  );
});

export default ProcessComponent;
ProcessComponent.displayName = 'ProcessComponent';

const JobListItem = ({ title, content, slotProps = {} }) => {
  const { listItem = {}, listItemText = {} } = { ...slotProps };
  return (
    <ListItem {...listItem}>
      <ListItemText
        primaryTypographyProps={{ variant: 'overline', color: 'primary', sx: { overflowWrap: 'anywhere' } }}
        secondaryTypographyProps={{ variant: 'body2', sx: { overflowWrap: 'anywhere' } }}
        {...listItemText}
        primary={title}
        secondary={content}
      />
    </ListItem>
  );
};

const RotatableArrow = ({ in: on }) => (
  <KeyboardArrowDown
    sx={(theme) => ({
      transition: theme.transitions.create('transform'),
      transform: 'rotate(0deg)',
      ...(on ? { transform: 'rotate(-180deg)' } : {}),
    })}
  />
);

const StatusIcon = ({ status }) =>
  status === 'completed' ? (
    <CheckCircle color="success" />
  ) : status === 'errored' ? (
    <Cancel color="error" />
  ) : (
    <CircularProgress size={20} />
  );
