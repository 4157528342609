import React, { useCallback } from 'react';

import View from './Items/View';
import Open from './Items/Open';
import OpenLocation from './Items/OpenLocation';
import Subheading from './Items/Subheading';
import Divider from './Items/Divider';
import SharableLink from './Items/SharableLink';
import MoveTo from './Items/MoveTo';
import ManageSubscriptions from './Items/ManageSubscriptions';
import Favorite from './Items/Favorite';
import LeaveMap from './Items/LeaveMap';
import Rename from './Items/Rename';
import Description from './Items/Description';
import Storage from './Items/Storage';
import ManageSharing from './Items/ManageSharing';
import MapSettings from './Items/MapSettings';
import Delete from './Items/Delete';
import PermanentlyDelete from './Items/PermanentlyDelete';
import NewProject from './Items/NewProject';
import NewFolder from './Items/NewFolder';
import NewTimestamp from './Items/NewTimestamp';
import Integrate from './Items/Integrate';
import NewFile from './Items/NewFile';
import NewMore from './Items/NewMore';
import Download from './Items/Download';

import { useContextMenu } from '../ContextMenuContext';
import EditParameters from './Items/EditParameters';

const useMenuMapper = ({ closeDialog, onClose, setDialog }) => {
  const { menuOptions } = useContextMenu();
  const { path } = menuOptions;

  const mapMenu = useCallback(
    (menuArray = []) =>
      menuArray
        .filter((x) => !!x)
        .map((menuString, i) => {
          switch (menuString) {
            case 'viewHeading':
              return <Subheading label="View" key={menuString + '_' + i + '_' + path?.id} />;
            case 'view':
              return <View onClose={onClose} key={menuString + '_' + i + '_' + path?.id} />;
            case 'open':
              return <Open onClose={onClose} key={menuString + '_' + i + '_' + path?.id} />;
            case 'sharableLink':
              return <SharableLink path={path} onClose={onClose} key={menuString + '_' + i + '_' + path?.id} />;
            case 'openLocation':
              return <OpenLocation path={path} onClose={onClose} key={menuString + '_' + i + '_' + path?.id} />;
            case 'divider':
              return <Divider key={menuString + '_' + i + '_' + path?.id} />;
            case 'organizeHeading':
              return <Subheading label="Organize" key={menuString + '_' + i + '_' + path?.id} />;
            case 'moveTo':
              return (
                <MoveTo
                  setDialog={setDialog}
                  path={path}
                  onClose={onClose}
                  closeDialog={closeDialog}
                  key={menuString + '_' + i + '_' + path?.id}
                />
              );
            case 'manageSubscription':
              return (
                <ManageSubscriptions
                  onClose={onClose}
                  setDialog={setDialog}
                  key={menuString + '_' + i + '_' + path?.id}
                />
              );
            case 'favorite':
              return (
                <Favorite
                  path={path}
                  onClose={onClose}
                  setDialog={setDialog}
                  key={menuString + '_' + i + '_' + path?.id}
                />
              );
            case 'integrate':
              return (
                <Integrate
                  path={path}
                  onClose={onClose}
                  setDialog={setDialog}
                  key={menuString + '_' + i + '_' + path?.id}
                />
              );
            case 'leaveMap':
              return (
                <LeaveMap
                  setDialog={setDialog}
                  onClose={onClose}
                  path={path}
                  key={menuString + '_' + i + '_' + path?.id}
                />
              );
            case 'manageHeader':
              return <Subheading label="Manage" key={menuString + '_' + i + '_' + path?.id} />;
            case 'rename':
              return (
                <Rename
                  setDialog={setDialog}
                  closeDialog={closeDialog}
                  onClose={onClose}
                  key={menuString + '_' + i + '_' + path?.id}
                />
              );
            case 'description':
              return (
                <Description
                  setDialog={setDialog}
                  closeDialog={closeDialog}
                  onClose={onClose}
                  key={menuString + '_' + i + '_' + path?.id}
                />
              );
            case 'storage':
              return <Storage setDialog={setDialog} onClose={onClose} key={menuString + '_' + i + '_' + path?.id} />;
            case 'manageSharing':
              return (
                <ManageSharing
                  setDialog={setDialog}
                  onClose={onClose}
                  path={path}
                  key={menuString + '_' + i + '_' + path?.id + '_' + path?.id}
                />
              );
            case 'mapSettings':
              return <MapSettings onClose={onClose} key={menuString + '_' + i + '_' + path?.id} />;
            case 'delete':
              return <Delete closeDialog={closeDialog} onClose={onClose} key={menuString + '_' + i + '_' + path?.id} />;
            case 'permanentlyDelete':
              return (
                <PermanentlyDelete
                  closeDialog={closeDialog}
                  setDialog={setDialog}
                  onClose={onClose}
                  key={menuString + '_' + i + '_' + path?.id}
                />
              );
            case 'download':
              return <Download onClose={onClose} key={menuString + '_' + i + '_' + path?.id} />;
            case 'newFolder':
              return <NewFolder close={onClose} key={menuString + '_' + i + '_' + path?.id} />;
            case 'newTimestamp':
              return <NewTimestamp close={onClose} key={menuString + '_' + i + '_' + path?.id} />;
            case 'newFile':
              return <NewFile close={onClose} setDialog={setDialog} key={menuString + '_' + i + '_' + path?.id} />;
            case 'newProject':
              return <NewProject close={onClose} key={menuString + '_' + i + '_' + path?.id} />;
            case 'newMore':
              return (
                <NewMore
                  open={menuOptions?.open}
                  close={onClose}
                  setDialog={setDialog}
                  key={menuString + '_' + i + '_' + path?.id}
                />
              );
            case 'editParameters':
              return (
                <EditParameters
                  path={path}
                  setDialog={setDialog}
                  closeDialog={closeDialog}
                  onClose={onClose}
                  key={menuString + '_' + i + '_' + path?.id}
                />
              );
            default:
              throw new Error(`Unrecognized menu item "${menuString}"`);
          }
        }),
    [closeDialog, menuOptions?.open, onClose, path, setDialog]
  );
  return mapMenu;
};
export default useMenuMapper;
