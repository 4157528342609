import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  TextField,
  Button,
  IconButton,
  DialogActions,
  Divider,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Stack,
  Alert,
  AlertTitle,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Collapse,
  Typography,
} from '@mui/material';
import { Add, Check, Delete, DragIndicator, Edit } from '@mui/icons-material';

import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useMinimalAuth } from 'hooks';

import ApiManager from 'ApiManager';
import { MarkdownEditor } from 'ReusableComponents/MarkdownEditor';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useMainContext } from 'ReusableComponents';

const Process = ({ edit, path, onClose }) => {
  const { currentPathId, mainMode, poll } = useCurrentFolder();

  const { addPath } = useMainContext();

  const [activeStep, setActiveStep] = useState(0);
  const [gitFile, setGitFile] = useState('');
  const [gitUrl, setGitUrl] = useState('');
  const [parameters, setParameters] = useState(path?.process?.parameters ?? []);
  const [paramEdit, setParamEdit] = useState(-1);
  const [processName, setProcessName] = useState('');

  const user = useMinimalAuth();

  const handleNext = useCallback(() => setActiveStep((prevActiveStep) => prevActiveStep + 1), []);

  const handleBack = useCallback(() => setActiveStep((prevActiveStep) => prevActiveStep - 1), []);

  const editParam = useCallback((i) => setParamEdit(i), []);

  const addParam = useCallback(
    () =>
      setParameters((old) => {
        editParam(old?.length);

        return [...old, {}];
      }),
    [editParam]
  );

  const setParam = useCallback(
    (i, type, value) => setParameters((old) => old.map((p, j) => ({ ...p, ...(i === j ? { [type]: value } : {}) }))),
    []
  );

  const removeParam = useCallback(
    (i) => {
      setParameters((old) => old.map((p, j) => i !== j && { ...p }).filter((x) => !!x));
      editParam(-1);
    },
    [editParam]
  );

  const postProcess = () => {
    const body = {
      name: processName,
      parentId: currentPathId,
      process: {
        gitUrl,
        gitFile,
        parameters,
      },
    };

    ApiManager.post('/v3/path/process', body, user)
      .then((res) => {
        addPath({ id: res.id, parentId: currentPathId, mainMode });

        onClose();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const updateProcess = () => {
    const body = {
      parameters,
    };

    console.log('BODY', body);

    ApiManager.patch(`/v3/path/${path?.id}/process`, body, user)
      .then(() => {
        onClose();
        path?.id === currentPathId && poll();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const isNextActive =
    (activeStep === 0 && gitUrl?.length > 0 && gitFile?.length > 0) ||
    (activeStep === 1 && paramEdit === -1) ||
    (activeStep === 2 && processName?.length > 0);

  const paramRef = useRef();
  useEffect(() => {
    paramRef.current = paramEdit;
  }, [paramEdit]);

  const setDescription = useCallback((value) => setParam(paramRef.current, 'description', value), [setParam]);

  const onDragEnd = useCallback(({ destination, source }) => {
    setParameters((old) => {
      const p = old?.[source?.index];
      old.splice(source?.index, 1);
      old.splice(destination?.index, 0, p);

      return old;
    });
  }, []);

  const DefineParameters = (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="parametersZone">
          {(provided) => (
            <List ref={provided.innerRef} {...provided.droppableProps}>
              {parameters.map(
                (p, i) =>
                  paramEdit === -1 && (
                    <Draggable key={String(`${p?.name}_${i}`)} draggableId={String(`${p?.name}_${i}`)} index={i}>
                      {(provided) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={`${p?.name}_${i}`}
                          secondaryAction={
                            <IconButton onClick={() => editParam(i)} edge="end" disabled={paramEdit > -1}>
                              <Edit />
                            </IconButton>
                          }
                        >
                          <ListItemIcon>
                            <DragIndicator />
                          </ListItemIcon>
                          <ListItemText
                            primary={p?.name}
                            secondary={p.description}
                            secondaryTypographyProps={{ noWrap: true }}
                          />
                        </ListItem>
                      )}
                    </Draggable>
                  )
              )}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
      <Collapse in={paramEdit > -1}>
        <>
          <Stack gap={1}>
            <Typography variant="subtitle2">Name</Typography>
            <TextField
              value={parameters[paramEdit]?.name ?? ''}
              onChange={(e) => setParam(paramEdit, 'name', e.target.value)}
              fullWidth
            />

            <Typography variant="subtitle2">Give an example</Typography>
            <TextField
              value={parameters[paramEdit]?.example ?? ''}
              onChange={(e) => setParam(paramEdit, 'example', e.target.value)}
              fullWidth
            />

            <Typography variant="subtitle2">Description</Typography>
            <MarkdownEditor
              key={paramEdit}
              disableButtons
              initialValue={parameters[paramEdit]?.description ?? ''}
              overrideState={[parameters[paramEdit]?.description ?? '', setDescription]}
              small
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <IconButton onClick={() => removeParam(paramEdit)}>
              <Delete />
            </IconButton>

            <IconButton
              color="primary"
              onClick={() => editParam(-1)}
              disabled={
                !(
                  parameters[paramEdit]?.name?.length &&
                  parameters[paramEdit]?.description?.length &&
                  parameters[paramEdit]?.example?.length
                )
              }
            >
              <Check />
            </IconButton>
          </Stack>
        </>
      </Collapse>
      {paramEdit === -1 && (
        <Button startIcon={<Add />} variant="contained" onClick={addParam}>
          Add parameter
        </Button>
      )}
    </>
  );

  return edit ? (
    <>
      <DialogContent>{DefineParameters}</DialogContent>
      {paramEdit === -1 && (
        <DialogActions>
          <Button onClick={updateProcess} variant="contained">
            Update
          </Button>
        </DialogActions>
      )}
    </>
  ) : (
    <>
      <DialogContent>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel>Add the Github URL and file path</StepLabel>
            <StepContent>
              <Stack gap={1}>
                <TextField
                  label="Github URL"
                  placeholder="https://github.com/ellipsis-drive/example"
                  value={gitUrl}
                  onChange={(e) => setGitUrl(e?.target?.value)}
                  fullWidth
                />
                <TextField
                  label="File path"
                  placeholder="src/lib/example.py"
                  value={gitFile}
                  onChange={(e) => setGitFile(e?.target?.value)}
                  fullWidth
                />

                <Alert severity="info">
                  <AlertTitle mt={-0.5}>Requirements</AlertTitle>
                  Mind to include a <b>requirements.txt</b> in the root of the git repository, listing all needed{' '}
                  <b>packages</b> and their <b>versions</b>.
                </Alert>
              </Stack>
            </StepContent>
          </Step>
          <Step completed={activeStep > 1 && parameters?.length >= 1}>
            <StepLabel optional={<Typography variant="caption">Optional</Typography>}>Define parameters</StepLabel>
            <StepContent>{DefineParameters}</StepContent>
          </Step>
          <Step>
            <StepLabel>Give the process a name</StepLabel>
            <StepContent>
              <TextField
                label="Name"
                placeholder="Process name"
                value={processName}
                onChange={(e) => setProcessName(e?.target?.value)}
                fullWidth
              />
            </StepContent>
          </Step>
        </Stepper>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button color="inherit" onClick={handleBack} disabled={activeStep <= 0}>
          Back
        </Button>
        {activeStep === 2 ? (
          <Button onClick={postProcess} disabled={!isNextActive} variant="contained">
            Finish
          </Button>
        ) : (
          <Button onClick={handleNext} disabled={!isNextActive}>
            Next
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default Process;
