import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';

import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import { DragIndicator } from '@mui/icons-material';
import ApiManager from 'ApiManager';
import { useMainContext } from 'ReusableComponents';
import { useMinimalAuth } from 'hooks';
import { useLayers } from 'Viewer/ViewerContexts/ViewerLayersContext';

const EditDialog = ({ open, onClose, properties, setProperties, sortProperty, layer }) => {
  const { onOpenSnackbar } = useMainContext();
  const { layersInfo, updateLayer } = useLayers();

  const user = useMinimalAuth();
  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      setProperties((old) =>
        update(old, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, old[dragIndex]],
          ],
        })
      );
    },
    [setProperties]
  );

  const handleCheckChange = useCallback(
    (i) => {
      setProperties((old) => {
        if (!old) {
          old = [];
        }
        const newProperties = [...old];
        newProperties[i].hidden = !newProperties[i]?.hidden;
        return newProperties;
      });
    },
    [setProperties]
  );

  const saveAsDefault = () => {
    let newOptions = {};
    if (layer.renderOptions) {
      newOptions = layer.renderOptions;
    }

    newOptions = {
      method: 'vector tiles',
      parameters: {
        zoom: layersInfo[layer.id].tilePyramid.zoom,
        mb: 2,
        step: 1000,
        amount: 1000,
      },
      centerPointOnly: false,
      lod: 6,
      ...newOptions,
    };
    newOptions.properties = properties.map((p) => {
      return { ...p };
    });
    updateLayer({ id: layer.id, renderOptions: newOptions });

    ApiManager.put(`/v3/path/${layer.id}/vector/renderOptions`, newOptions, user)
      .then((r) => {
        onOpenSnackbar({
          level: 'success',
          content: 'Default saved',
        });
      })
      .catch((e) => {
        console.log(e);
        onOpenSnackbar({
          level: 'error',
          content: e.message,
        });
      });
  };

  const renderItem = useCallback(
    (p, i) => (
      <DraggableListItem
        key={p.id}
        index={i}
        id={p.id}
        text={p.name}
        moveItem={moveItem}
        hidden={p.hidden}
        handleCheckChange={handleCheckChange}
        isSortProperty={p?.id === sortProperty?.[0]?.id}
      />
    ),
    [handleCheckChange, moveItem, sortProperty]
  );

  const handleSelect = useCallback(
    (hidden) => setProperties((properties) => properties.map((p) => ({ ...p, hidden }))),
    [setProperties]
  );

  const selectAll = useCallback(() => handleSelect(false), [handleSelect]);
  const deselectAll = useCallback(() => handleSelect(true), [handleSelect]);

  const selectedNum = useMemo(() => properties?.filter((p) => p?.hidden)?.length, [properties]);

  const saveDisabled = useMemo(() => {
    return (
      layer?.renderOptions?.properties?.map((x) => x.name + '_' + x.hidden).join(',') ===
      properties.map((x) => x.name + '_' + x.hidden).join(',')
    );
  }, [layer?.renderOptions, properties]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle title="Edit Columns" onClose={onClose} />
      <DialogContent sx={{ overflow: 'hidden', display: 'grid', gridTemplateRows: 'auto 1fr auto', gap: 1, pr: 0 }}>
        <Stack direction="row">
          <Button onClick={selectAll} disabled={selectedNum !== properties?.length}>
            Select All
          </Button>
          <Button onClick={deselectAll} disabled={selectedNum === properties?.length}>
            Deselect All
          </Button>
        </Stack>

        <List dense sx={{ overflow: 'auto', pr: 3 }}>
          {properties?.map((p, i) => renderItem(p, i))}
        </List>

        {layer.yourAccess.accessLevel >= ApiManager.newAccessLevel['edit+'] && (
          <Box>
            <Button onClick={saveAsDefault} disabled={saveDisabled}>
              {' '}
              Save as default{' '}
            </Button>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;

const ItemTypes = { list: 'list' };

const DraggableListItem = ({ id, text, index, moveItem, hidden, handleCheckChange, isSortProperty = false }) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.list,
    collect: (monitor) => ({ handlerId: monitor.getHandlerId() }),
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (
        (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
        (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
      ) {
        return;
      }

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.list,
    item: () => ({ id, index }),
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <ListItem ref={ref} sx={{ cursor: 'move', opacity, px: 0 }} data-handler-id={handlerId}>
      <ListItemIcon sx={{ minWidth: ({ spacing }) => spacing(4) }}>
        <DragIndicator />
      </ListItemIcon>
      <Tooltip title={!!isSortProperty ? 'Not allowed to hide sorted property' : undefined}>
        <ListItemIcon>
          <Checkbox checked={!hidden} onChange={() => handleCheckChange(index)} disabled={isSortProperty} />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary={text} />
    </ListItem>
  );
};
