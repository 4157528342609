import React, { forwardRef, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import moment from 'moment';
import { useHistory, Link } from 'react-router-dom';

import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from '@mui/material';
import { Delete, PersonAdd } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import CustomAvatar from 'ReusableComponents/CustomAvatar';
import { SetupProgressWithSteps } from '../SetupProgress';

import { useAuth, useAccountsAuth, useAuthFuncs } from '../../Account/AuthContext';

import VersionUtility from 'VersionUtility';
import ApiManager from 'ApiManager';

const useStyles = makeStyles({ name: 'AccountMenu' })((theme) => ({
  paper: { maxHeight: `unset`, left: 'unset !important', right: 16, transformOrigin: 'top right !important' },
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    overflow: 'hidden',
    maxHeight: `calc(100vh - 64px - ${theme.spacing(4)})`,
    maxWidth: 400,
    gridTemplateRows: 'auto auto auto auto auto 1fr auto auto auto',
    '&>*': { minWidth: 0 },
  },
  progressBar: {
    color: 'red',
    '& .MuiLinearProgress-dashed': {
      display: 'none',
    },
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: theme.spacing(7),
    minWidth: 0,
    whiteSpace: 'normal',
    textAlign: 'left',
  },
  setupProgressContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
  },
  accountsContainer: { maxHeight: '100%', overflow: 'auto', boxSizing: 'border-box' },
  menuItem: {
    minHeight: theme.spacing(6),
    justifyContent: 'center',
  },
  divider: {
    margin: '6px 0px',
  },
}));

const AccountMenu = forwardRef(({ open, setOpen, anchorRef, setAccountMenuOpen }, ref) => {
  const { classes } = useStyles();
  const user = useAuth();
  const accounts = useAccountsAuth();
  const { signout, switchAccount, removeAccount } = useAuthFuncs();
  const history = useHistory();

  const handleSettingsClick = (e) => {
    handleClose(e);
  };

  const handleSwitch = async (e, user) => {
    try {
      let info = await ApiManager.get('/v3/account', null, user);
      info = VersionUtility.convertAccount(info);
      let newUser = Object.assign(user, info);
      if (newUser.history) {
        switchAccount(newUser);
      } else {
        history.push('/login', { from: `${history.location.pathname}${history.location.search}` });
      }
    } catch (e) {
      console.log(e);
      history.push('/login', { from: `${history.location.pathname}${history.location.search}` });
    }
    handleClose(e);
  };

  const handleDeleteAccount = (e, user) => {
    removeAccount(user);
    handleClose(e);
  };

  const handleLogout = (e) => {
    handleClose(e);
    signout();
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [anchorRef, open]);

  return (
    <Popover
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={{ top: 64, left: 0 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{ className: classes.paper }}
      onClose={handleClose}
    >
      <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown} className={classes.list}>
        {user && !isEmpty(user) ? (
          [
            <MenuItem key={user?.id + '_item'} className={classes.menuItem}>
              <ListItemAvatar>
                <CustomAvatar user={user} />
              </ListItemAvatar>
              <ListItemText
                primary={user.username}
                secondary={user.email}
                secondaryTypographyProps={{ sx: { whiteSpace: 'normal' } }}
              />
            </MenuItem>,
            <MenuItem style={{ justifyContent: 'center' }} key={user?.id + '_manage'}>
              <Button
                className={classes.accountButton}
                variant="outlined"
                color="primary"
                component={Link}
                to="/account-settings/profile"
                onClick={handleSettingsClick}
              >
                Manage account
              </Button>
            </MenuItem>,
            <Divider className={classes.divider} key="divider_1" />,
            <SetupProgressWithSteps setAccountMenuOpen={setAccountMenuOpen} key={user?.id + '_setup'} />,
            <Divider className={classes.divider} key="divider_2" />,
            accounts && accounts.length > 1 && (
              <List className={classes.accountsContainer} key={user?.id + '_' + accounts?.length}>
                {accounts.map(
                  (account) =>
                    account.id !== user.id && (
                      <AccountMenuItem
                        key={account.id + '_external'}
                        account={account}
                        handleSwitch={handleSwitch}
                        handleDeleteAccount={handleDeleteAccount}
                      />
                    )
                )}
              </List>
            ),
            <MenuItem
              onClick={() => {
                setOpen(false);
                history.push('/login', {
                  feature: 'addAnotherAccount',
                  from: `${history.location.pathname}${history.location.search}`,
                });
              }}
              className={classes.avatarContainer}
              key={user?.id + '_add'}
            >
              <ListItemIcon color="inherit" style={{ minWidth: 56 }}>
                <PersonAdd />
              </ListItemIcon>
              Add another account
            </MenuItem>,
            <Divider className={classes.divider} key="divider_3" />,
            <MenuItem className={classes.menuItem} onClick={handleLogout} key={user?.id + '_logout'}>
              Log out
            </MenuItem>,
          ]
        ) : (
          <MenuItem
            className={classes.menuItem}
            onClick={() => history.push('/login', { from: `${window.location.pathname}${window.location.search}` })}
          >
            Log in
          </MenuItem>
        )}
      </MenuList>
    </Popover>
  );
});

const AccountMenuItem = ({ account, handleSwitch, handleDeleteAccount }) => {
  const { classes } = useStyles();
  const tokenExpired = account?.expires && moment(account.expires).isBefore(moment());

  const secondaryListText = tokenExpired ? (
    <>
      <Typography color="text.secondary" variant={'body2'} sx={{ overflowWrap: 'anywhere', lineHeight: 1.25 }}>
        {account.email}
      </Typography>
      <Typography color="text.secondary" variant={'overline'}>
        {'Signed out'}
      </Typography>
    </>
  ) : (
    <Typography color="text.secondary" variant={'body2'} sx={{ overflowWrap: 'anywhere', lineHeight: 1.25 }}>
      {account.email}
    </Typography>
  );
  return (
    <ListItem
      disablePadding
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={(e) => {
            handleDeleteAccount(e, account);
          }}
        >
          <Delete />
        </IconButton>
      }
      key={account.id + '_internal'}
      className={classes.avatarContainer}
    >
      <ListItemButton onClick={(e) => handleSwitch(e, account)}>
        <ListItemAvatar style={{ display: 'flex', alignItems: 'center' }}>
          <CustomAvatar user={account} size={32} />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <Typography color="inherit" variant="body1">
              {account.username}
            </Typography>
          }
          secondary={secondaryListText}
        />
      </ListItemButton>
    </ListItem>
  );
};

AccountMenu.displayName = 'accountMenu';

AccountMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  anchorRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

export default AccountMenu;
