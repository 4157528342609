import React, { createContext, useCallback, useContext, useState } from 'react';
import isEqual from 'lodash/isEqual';

const consentValueContext = createContext();
const consentSetterContext = createContext();

const LATEST_COOKIE_VERSION = 1;
export const COOKIE_TYPES = {
  ESSENTIAL: 'essential',
  ANALYTICAL: 'analytical',
  PERSONALIZATION: 'personalization',
  ADVERTISING: 'advertising',
};

export const INITIAL_COOKIE_VALUE = { unset: true };

const GET_CONSENT_VALUE = () => {
  try {
    const storedConsent = localStorage.getItem('consent');

    if (!!storedConsent) {
      const parsed = JSON.parse(storedConsent);

      if (parsed?.version === LATEST_COOKIE_VERSION) {
        return parsed;
      } else {
        return INITIAL_COOKIE_VALUE;
      }
    } else {
      return INITIAL_COOKIE_VALUE;
    }
  } catch (e) {
    return Object.values(COOKIE_TYPES).reduce((pv, cv) => ({ ...pv, [cv]: cv === COOKIE_TYPES.ESSENTIAL }), {});
  }
};

export const ConsentProvider = ({ children }) => {
  const [consent, setConsent] = useState(GET_CONSENT_VALUE);

  const consentSetter = useCallback((input) => {
    const nv = { ...input, version: LATEST_COOKIE_VERSION };

    if (!isEqual(GET_CONSENT_VALUE(), nv)) {
      localStorage.setItem('consent', JSON.stringify(nv));
      setConsent(nv);
    }
  }, []);

  return (
    <consentSetterContext.Provider value={consentSetter}>
      <consentValueContext.Provider value={consent}>{children}</consentValueContext.Provider>
    </consentSetterContext.Provider>
  );
};

export const useConsentValue = () => {
  const context = useContext(consentValueContext);

  if (context === undefined) {
    throw new Error('useConsentValue must be used within a ConsentProvider');
  }

  return context;
};

export const useConsentSetter = () => {
  const context = useContext(consentSetterContext);

  if (context === undefined) {
    throw new Error('useConsentSetter must be used within a ConsentProvider');
  }

  return context;
};

export const usePushToDataLayer = () => {
  const consent = useConsentValue();

  const pushToDataLayer = (props) => {
    import('react-gtm-module').then((TagManager) => {
      TagManager.dataLayer({
        gtmId: 'GTM-K72V8Z6',
        ...props,
      });
    });
  };

  return consent?.[COOKIE_TYPES?.ANALYTICAL] ? pushToDataLayer : () => {};
};

export function withPushToDataLayer(Component) {
  return function WrapperComponent(props) {
    const pushToDataLayer = usePushToDataLayer();
    return <Component {...props} pushToDataLayer={pushToDataLayer} />;
  };
}
